// @ts-ignore
import { defaults } from './defaultConfigs';

export const Localization = {
  ...defaults,
  assignedCs: 'Assigned FE',
  csId: 'FE ID',
  currencySymbol: 'रू',
  currencyAbbrev: 'NPR',
  branch: 'Teams',
  accountBranch: 'Area',
  branches: 'Teams',
  collectionSpecialist: 'Field Executives',
  principalPayment: 'Principal Payment*',
  showPenaltyPayment: false,
  penaltyPayment: 'Penalty Payment*',
  csLeaderBoard: 'Field Exec Leaderboard',
  branchLeaderBoard: 'Team Leaderboard',
  collectionsBarChartTitle: 'Field Collections',
  accountsBarChartTitle: 'Field Accounts',
  collectionSpecialistPerformanceTitle: 'Field Executives Performance',
  branchListingHeader: 'Teams',
  branchListingBreadcrumb: 'Teams',
  csListingHeader: 'Field Executives',
  csListingBreadcrumb: 'Field Executives',
  csDetailsBreadcrumb: 'Field Executive',
  logoFilename: 'jagdamba.png',
  clientName: 'JCIPL',
  navbarBackgroundColor: '#2A2A3E',
  showManuals: true,
  showAIManuals: false,
  showAPK: false,
  csManualLink: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/artha/manuals/JCIPL_FieldExecutive_UserManual.pdf',
  cashierManualLink: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/artha/manuals/JCIPL_Cashier_UserManual.pdf',
  managerManualLink: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/artha/manuals/JCIPL_Manager_UserManual.pdf',
  borrowerManualLink: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/artha/manuals/JCIPL_Borrower_UserManual.pdf',
  dealerManualLink: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/artha/manuals/JCIPL_Dealer_UserManual.pdf',
  aiManualLink: '',
  apkLink: process.env.APK_URL || '',
  eulaTextUrl: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_JMPL_webapp.txt',
  eulaVersionUrl: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_JMPL_webapp.json',
  privacyStatementUrl:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.txt',
  privacyStatementVersion:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.json',
  zipcodeLength: 5,
  zipcodeValidationMsg: 'Nepal zipcode should have 5 characters. Ex: 10101, 44609',
  country: 'Nepal',
  countryAbbrev: 'NP',
  transactionListingHeader: 'Transaction Listing',
  transactionListingBreadcrumb: 'Transaction Listing',
  activateTransactionListing: true,
  activateCreditDebitMemo: true,
  activatePPD: false,
  currencyLocale: 'en-IN',
  hiddenPenaltyField: true,
  csReportDownload: false,
  cashierReportDownload: true,
  appId: '1:834347563222:web:09da0f75746933729ab177',
  measurementId: 'G-S28HXTW0GV',
  showTranslation: false,
  recoveredAccountsLabel: 'ACCOUNTS VISITED',
  noOfPaymentsLabel: 'TOTAL NO. OF RECORDS',
  showCashierPage: true,
  displayClientCode: false,
  metricsToDisplay: [
    'mtd-collection-efficiency',
    'account-recovery-rate',
    'average-visits-per-day',
    'collections-accounts-barchart',
  ],
  cspStatsToHide: [],
  showSummaryReportDownload: true,
  collectionSpecialistItinerary: 'Field Executive Itinerary',
  eReceipt: true,
};
